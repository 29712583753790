<template>
  <b-modal
    id="pay_installment_modal"
    :title="$t('Baixa da conta')"
    centered
    size="xl"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    @hidden="cleanModalData"
  >
    <e-spinner v-if="loading" />
    <b-card-actions
      v-else
      :title="$t('Dados do pagamento')"
      :show-loading="saving"
      no-actions
    >
      <FormulateForm
        ref="modalPayForm"
        name="modalPayForm"
      >
        <b-row v-if="!isBulkPay">
          <b-col md="3">
            <FormulateInput
              id="modal_pay_installment-value"
              v-model="installmentPayForm.value"
              type="label"
              filter="currency"
              :label="$t('Valor da parcela')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="modal_pay_installment-discount"
              v-model="installmentPayForm.discount"
              type="text-number"
              currency="R$"
              :precision="2"
              :label="$t('Desconto')"
              @blur="(value) => onInputDiscount(value, installmentPayForm)"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="modal_pay_installment-interest"
              v-model="installmentPayForm.interest"
              type="text-number"
              currency="R$"
              :precision="2"
              :label="$t('Juros')"
              @blur="(value) => onInputInterest(value, installmentPayForm)"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="modal_pay_installment-payment_value"
              v-model="installmentPayForm.paymentValue"
              type="label"
              filter="currency"
              :label="$t('Valor a pagar')"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <e-checking-account-combo
              id="modal_pay_installment-input_checking_account"
              v-model="installmentPayForm.checkingAccountId"
              type="External"
              :store-id="storeId"
              required
              active-options
              @single-option="(checkAccId) => (installmentPayForm.checkingAccountId = checkAccId)"
            />
          </b-col>

          <b-col md="4">
            <FormulateInput
              id="modal_pay_installment-input_payment_date"
              ref="inputPaymentDate"
              v-model="installmentPayForm.paymentDate"
              type="datepicker"
              :hour-default="12"
              :label="$t('Data pagamento')"
              :placeholder="$t('dd/mm/aaaa')"
              class="required"
              validation="required"
            />
          </b-col>
        </b-row>

        <b-row v-if="isBulkPay">
          <b-col>
            <FormulateInput
              id="modal_pay_installment-group_items"
              v-model="groupItems"
              type="switch"
              :label="$t('Agrupar baixas?')"
              :instructions="[
                {
                  text: $t(
                    'Serão criados lançamentos na conta corrente agrupados por Forma de Pagamento e Bandeira do Cartão (quando aplicável), com o valor da soma das parcelas agrupadas'
                  ),
                },
                {
                  text: $t(
                    'O estorno de uma parcela baixada em grupo implicará no estorno de todas as parcelas que foram baixadas juntas'
                  ),
                },
              ]"
            />
          </b-col>
        </b-row>
        <b-row v-if="isBulkPay">
          <b-col>
            <b-table
              show-empty
              responsive
              class="bordered"
              :fields="fields"
              :items="installmentBulkPay"
            >
              <template #cell(discount)="row">
                <FormulateInput
                  :id="`modal_bulk_pay_installment-discount-${row.index}`"
                  v-model="row.item.discount"
                  type="text-number"
                  currency="R$"
                  :precision="2"
                  @blur="(value) => onInputDiscount(value, row.item)"
                />
              </template>
              <template #cell(interest)="row">
                <FormulateInput
                  :id="`modal_bulk_pay_installment-interest-${row.index}`"
                  v-model="row.item.interest"
                  type="text-number"
                  currency="R$"
                  :precision="2"
                  @blur="(value) => onInputInterest(value, row.item)"
                />
              </template>
              <template #cell(paymentValue)="row">
                <FormulateInput
                  :id="`modal_bulk_pay_installment-payment_value-${row.index}`"
                  v-model="row.item.paymentValue"
                  type="label"
                  filter="currency"
                  :precision="2"
                />
              </template>

              <template #cell(action)="row">
                <e-grid-actions
                  :show-update="false"
                  :show-delete="true"
                  @update="() => {}"
                  @delete="onRemoveInstallment(row.item)"
                />
              </template>

              <template #custom-foot>
                <tr>
                  <th
                    colspan="4"
                    class="text-right"
                  >
                    Total
                  </th>
                  <th class="text-right">
                    {{ getTotalInfos.totalInstallmentsValue | currency }}
                  </th>
                  <th class="text-right">
                    {{ getTotalInfos.totalDiscountValue | currency }}
                  </th>
                  <th class="text-right">
                    {{ getTotalInfos.totalInterestValue | currency }}
                  </th>
                  <th class="text-right">
                    {{ getTotalInfos.totalPaymentValue | currency }}
                  </th>
                </tr>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row v-else />
      </FormulateForm>
    </b-card-actions>

    <b-row class="mt-1 justify-content-end">
      <b-col class="d-flex justify-content-end">
        <e-button
          id="modal_pay_installment-btn_cancel"
          class="mr-1"
          variant="outline-primary"
          :text="$t('Cancelar')"
          :text-shortcuts="['ESC']"
          @click="hideModal"
        />

        <e-button
          id="modal_pay_installment-btn_confirm"
          variant="primary"
          icon="cash"
          :text="$t('Confirmar baixa')"
          :busy="saving"
          @click="onConfirm"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol, BTable } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { alerts } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import ESpinner from '@/views/components/ESpinner.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import ECheckingAccountCombo from '@/views/components/inputs/ECheckingAccountCombo.vue'
import Decimal from 'decimal.js-light'

const getInitialInstallmentPayForm = () => ({
  id: null,
  checkingAccountId: null,
  paymentDate: new Date(),
  paymentValue: 0,
  value: 0,
  interest: 0,
  discount: 0,
})

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BTable,
    EButton,
    BCardActions,
    ESpinner,
    EGridActions,
    ECheckingAccountCombo,
  },

  mixins: [alerts],

  props: {},

  data() {
    return {
      loading: false,
      saving: false,
      isBulkPay: false,
      groupItems: false,
      installmentPayForm: getInitialInstallmentPayForm(),
      installmentBulkPay: [],
      storeId: null,
    }
  },

  computed: {
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Descrição'),
          key: 'accountsPayable.description',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Parcela'),
          key: 'installment',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '60px' },
        },
        {
          label: this.$t('Vencimento'),
          key: 'dueDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '120px' },
          formatter: val => this.$options.filters.date(val),
        },
        {
          label: this.$t('Valor da parcela'),
          key: 'value',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: val => this.$options.filters.currency(val),
        },
        {
          label: this.$t('Desconto'),
          key: 'discount',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '130px', minWidth: '130px' },
        },
        {
          label: this.$t('Juros'),
          key: 'interest',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '130px', minWidth: '130px' },
        },
        {
          label: this.$t('Valor a pagar'),
          key: 'paymentValue',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '130px', minWidth: '130px' },
        },
      ]
    },

    getTotalInfos() {
      return this.installmentBulkPay.reduce(
        (totals, inst) => ({
          totalInstallmentsValue: (inst.value || 0) + totals.totalInstallmentsValue,
          totalInterestValue: (inst.interest || 0) + totals.totalInterestValue,
          totalDiscountValue: (inst.discount || 0) + totals.totalDiscountValue,
          totalPaymentValue: (inst.paymentValue || 0) + totals.totalPaymentValue,
        }),
        {
          totalInstallmentsValue: 0,
          totalInterestValue: 0,
          totalDiscountValue: 0,
          totalPaymentValue: 0,
        }
      )
    },
  },

  methods: {
    async onConfirm() {
      if (!this.validForm()) {
        this.showInvalidDataMessage()
        return
      }

      this.saving = true
      try {
        if (this.isBulkPay) {
          const payload = this.installmentBulkPay.map(inst =>
            this.prepareToSavePay(this.installmentPayForm, inst)
          )
          await this.$http.patch('/api/accounting/accounts-payable-installments/bulk-pay', {
            accountsPayableInstallments: payload,
            groupItems: this.groupItems,
          })
          this.showSuccess({ message: this.$t('Pagamento em massa concluído.') })
        } else {
          const payload = this.prepareToSavePay(this.installmentPayForm)
          await this.$http.patch('/api/accounting/accounts-payable-installments/pay', payload)
          this.showSuccess({ message: this.$t('Pagamento concluído.') })
        }

        const itemList = this.isBulkPay ? this.installmentBulkPay : [this.installmentPayForm]
        this.$emit('after-confirm', { itemList })
        this.hideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    prepareToSavePay(paymentForm, data = getInitialInstallmentPayForm()) {
      return {
        ...data,
        id: data.id || paymentForm.id,
        checkingAccountId: paymentForm.checkingAccountId,
        paymentDate: paymentForm.paymentDate,
        paymentValue: data.paymentValue || paymentForm.paymentValue,
        interest: data.interest || paymentForm.interest,
        discount: data.discount || paymentForm.discount,
      }
    },

    async showModal(isBulk, installmentsList) {
      this.loading = true
      this.$bvModal.show('pay_installment_modal')
      this.isBulkPay = isBulk

      try {
        const { data } = await this.$http.get(
          '/api/accounting/accounts-payable-installments/list-to-pay',
          { params: { accountsPayableInstallmentIds: installmentsList } }
        )

        if (!data?.length) {
          throw new Error(this.$t('Parcela a pagar não encontrada.'))
        }

        this.storeId = data[0].accountsPayable.store.id
        const defaultData = getInitialInstallmentPayForm()
        if (isBulk) {
          this.installmentBulkPay = data.map(inst => ({
            ...defaultData,
            ...inst,
            paymentValue: inst.value,
            paymentDate: defaultData.paymentDate,
          }))
        } else {
          const installment = data[0]
          this.installmentPayForm = {
            ...defaultData,
            ...installment,
            paymentValue: installment.value,
            paymentDate: defaultData.paymentDate,
          }
        }
      } catch (error) {
        this.showError({ error })
        this.hideModal()
      } finally {
        this.loading = false
      }
    },
    cleanModalData() {
      this.installmentPayForm = getInitialInstallmentPayForm()
      this.installmentBulkPay = []
      this.storeId = null
    },
    hideModal() {
      this.cleanModalData()
      this.$bvModal.hide('pay_installment_modal')
    },

    onInputDiscount(discount, item) {
      // eslint-disable-next-line no-param-reassign
      item.paymentValue = this.calculatePaymentValue(item.value, item.interest, discount)
    },
    onInputInterest(interest, item) {
      // eslint-disable-next-line no-param-reassign
      item.paymentValue = this.calculatePaymentValue(item.value, interest, item.discount)
    },

    calculatePaymentValue(originalValue, interest, discount) {
      const result = new Decimal(originalValue || 0)
        .sub(discount || 0)
        .add(interest || 0)
        .toNumber()
      if (!result) return 0
      return result
    },

    onRemoveInstallment(item) {
      const indexFound = this.installmentBulkPay.findIndex(
        installment => installment.id === item.id
      )
      this.installmentBulkPay.splice(indexFound, 1)
      this.$emit('remove-installment', item)
    },

    validForm() {
      this.$refs.modalPayForm.showErrors()
      const { paymentDate, checkingAccountId } = this.installmentPayForm

      if (!paymentDate) return false
      this.$refs.inputPaymentDate.validationErrors = []

      if (!checkingAccountId) return false

      return true
    },
  },
}
</script>

<style lang="scss" scoped></style>
