var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        ref: "bills-table",
        class: {
          bordered: true,
          "mb-1": true,
          "bill-pay-select-row": _vm.hasSelectRow,
        },
        attrs: {
          "show-empty": "",
          striped: "",
          small: !_vm.isPdv,
          responsive: "",
          "tbody-tr-class": _vm.rowClass,
          fields: _vm.fields,
          items: _vm.items,
          "empty-text": _vm.getEmptyTableMessage(
            _vm.$tc("ACCOUNTS_PAYABLE.NAME"),
            "female"
          ),
          "no-local-sorting": "",
          "sort-by": _vm.sorting.sortBy,
          "sort-desc": _vm.sorting.sortDesc,
        },
        on: {
          "update:sortBy": function ($event) {
            return _vm.$set(_vm.sorting, "sortBy", $event)
          },
          "update:sort-by": function ($event) {
            return _vm.$set(_vm.sorting, "sortBy", $event)
          },
          "update:sortDesc": function ($event) {
            return _vm.$set(_vm.sorting, "sortDesc", $event)
          },
          "update:sort-desc": function ($event) {
            return _vm.$set(_vm.sorting, "sortDesc", $event)
          },
          "row-clicked": _vm.onRowClickToSelectPay,
          "sort-changed": _vm.getData,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "cell(action)",
              fn: function (row) {
                return [
                  _c("e-grid-actions", {
                    attrs: {
                      "show-update":
                        !_vm.allActionsBlocked(row.item) &&
                        _vm.showUpdateAction(),
                      "show-delete":
                        !_vm.allActionsBlocked(row.item) &&
                        _vm.canDelete(row.item),
                      "show-read-only": _vm.showReadOnlyAction(),
                      buttons: _vm.gridActions(row),
                    },
                    on: {
                      update: function ($event) {
                        return _vm.updateBill(row)
                      },
                      "pay-account": function ($event) {
                        return _vm.showPayModal(row)
                      },
                      "reverse-payment": function ($event) {
                        return _vm.reversePayment(row.item)
                      },
                      "read-only": function ($event) {
                        return _vm.showDetails(row)
                      },
                      delete: function ($event) {
                        return _vm.onDelete(row.item)
                      },
                      clone: function ($event) {
                        return _vm.clone(row.item)
                      },
                    },
                  }),
                ]
              },
            },
            _vm.isPdv
              ? {
                  key: "cell(aditionalInfo)",
                  fn: function (row) {
                    return [
                      _c("b", [
                        _vm._v(_vm._s(_vm.$t("Conta Gerencial")) + ":"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            row.item.accountsPayable.managementAccount.name
                          )
                      ),
                      _c("br"),
                      row.item.accountsPayable.supplier
                        ? _c("div", [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("Fornecedor")) + ":"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("cpfCnpj")(
                                    row.item.accountsPayable.supplier.document
                                  )
                                ) +
                                " - " +
                                _vm._s(row.item.accountsPayable.supplier.name) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      row.item.accountsPayable.customer
                        ? _c("div", [
                            _c("b", [_vm._v(_vm._s(_vm.$t("Cliente")) + ":")]),
                            _vm._v(
                              " " +
                                _vm._s(row.item.accountsPayable.customer.name) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("b", [_vm._v(_vm._s(_vm.$t("Desc.")) + ":")]),
                      _vm._v(
                        " " +
                          _vm._s(row.item.accountsPayable.description || "-")
                      ),
                      _c("br"),
                      row.item.accountsPayable.payBox
                        ? _c("div", [
                            _c("b", [_vm._v(_vm._s(_vm.$t("Caixa Nº")) + ":")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.item.accountsPayable.payBox.number || "-"
                                )
                            ),
                            _c("br"),
                          ])
                        : _vm._e(),
                      _c("b", [_vm._v(_vm._s(_vm.$t("Criado por")) + ":")]),
                      _vm._v(
                        " " +
                          _vm._s(row.item.createdBy || "-") +
                          " (" +
                          _vm._s(_vm._f("datetime")(row.item.createdDate)) +
                          ")"
                      ),
                      _c("br"),
                      _vm.showModifiedBy(row.item)
                        ? _c("div", [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("Alterado por")) + ":"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(row.item.modifiedBy || "-") +
                                " (" +
                                _vm._s(
                                  _vm._f("datetime")(row.item.modifiedDate)
                                ) +
                                ")"
                            ),
                            _c("br"),
                          ])
                        : _vm._e(),
                      row.item.writtenOffBy
                        ? _c("div", [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  row.item.financialWriteOffIsActive
                                    ? _vm.$t("Baixado por")
                                    : _vm.$t("Baixa estornada por")
                                ) + ":"
                              ),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(row.item.writtenOffBy || "-") +
                                " (" +
                                _vm._s(
                                  _vm._f("datetime")(row.item.writtenOffDate)
                                ) +
                                ")"
                            ),
                            _c("br"),
                          ])
                        : _vm._e(),
                      row.item.checkingAccount
                        ? _c("div", [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("Conta corrente")) + ":"),
                            ]),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.item.checkingAccount
                                      ? row.item.checkingAccount.name
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      row.item.accountsPayable.comments || row.item.comments
                        ? _c("div", [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("Observação")) + ":"),
                            ]),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    [
                                      row.item.accountsPayable.comments,
                                      row.item.comments,
                                    ]
                                      .filter(Boolean)
                                      .join(" - ")
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("br"),
                          ])
                        : _vm._e(),
                    ]
                  },
                }
              : null,
            _vm.isPdv
              ? {
                  key: "cell(info)",
                  fn: function (row) {
                    return [
                      _c("b", [_vm._v(_vm._s(_vm.$t("Id da Parcela")) + ":")]),
                      _vm._v(" " + _vm._s(row.item.id)),
                      _c("br"),
                      _c("b", [_vm._v(_vm._s(_vm.$t("Loja")) + ":")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("storeName")(row.item.accountsPayable.store)
                          )
                      ),
                      _c("br"),
                      row.item.accountsPayable.document
                        ? _c("div", [
                            _c("b", [_vm._v(_vm._s(_vm.$t("Nº doc.")) + ":")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  "" +
                                    row.item.accountsPayable.document +
                                    (row.item.accountsPayable.documentType
                                      ? " (" +
                                        row.item.accountsPayable.documentType +
                                        ")"
                                      : "")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("b", [_vm._v(_vm._s(_vm.$t("Parc.")) + ":")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            row.item.installment
                              ? [
                                  row.item.installment,
                                  row.item.accountsPayable.installmentCount,
                                ]
                                  .filter(Boolean)
                                  .join("/")
                              : "-"
                          )
                      ),
                      _c("br"),
                      _c("b", [_vm._v(_vm._s(_vm.$t("Forma Pagto.")) + ":")]),
                      _vm._v(" " + _vm._s(row.item.paymentMethod.name)),
                      _c("br"),
                      row.item.accountsPayable.purchaseOrderId
                        ? _c(
                            "div",
                            [
                              _c("b", [_vm._v(_vm._s(_vm.$t("Pedido")) + ":")]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "hyperlink",
                                  attrs: {
                                    to: {
                                      name: "purchase-order-details",
                                      params: {
                                        id: row.item.accountsPayable
                                          .purchaseOrderId,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "ExternalLinkIcon" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.item.accountsPayable.purchaseOrderId
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      row.item.accountsPayable.saleId
                        ? _c(
                            "div",
                            [
                              _c("b", [_vm._v(_vm._s(_vm.$t("Venda")) + ":")]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "hyperlink",
                                  attrs: {
                                    to: {
                                      name: "order-read-only",
                                      params: {
                                        id: row.item.accountsPayable.saleId,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "ExternalLinkIcon" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(row.item.accountsPayable.saleId) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      row.item.accountsPayable.statementDate &&
                      row.item.accountsPayable.statementDate !==
                        row.item.accountsPayable.createdDate
                        ? _c("div", [
                            _c("b", [_vm._v(_vm._s(_vm.$t("Emis.")) + ":")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("date")(
                                    row.item.accountsPayable.statementDate
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("b", [_vm._v(_vm._s(_vm.$t("Lanç.")) + ":")]),
                      row.item.accountsPayable.createdDate
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("date")(
                                    row.item.accountsPayable.createdDate
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                      _c("br"),
                      _c("b", [_vm._v(_vm._s(_vm.$t("Venc.")) + ":")]),
                      row.item.dueDate
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("date")(row.item.dueDate)) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                      _c("br"),
                      _c("b", [_vm._v(_vm._s(_vm.$t("Pago")) + ":")]),
                      row.item.paymentDate
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("date")(row.item.paymentDate)) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                }
              : null,
            {
              key: "head(bulkPay)",
              fn: function () {
                return [
                  _vm.hasSelectRow
                    ? _c(
                        "p",
                        { staticClass: "ml-1" },
                        [
                          _c("e-instructions", {
                            attrs: {
                              title: _vm.$t("Baixa em massa"),
                              instruction: _vm.$t(
                                "Selecione os registros para baixar e depois vá no botão inferior direto da tela para ver as opções de pagamento em massa."
                              ),
                            },
                          }),
                          _c("FormulateInput", {
                            staticClass: "mt-1",
                            attrs: {
                              type: "e-checkbox",
                              title: _vm.$t(
                                "Marca ou desmarca todos os itens desta página."
                              ),
                            },
                            on: {
                              change: function (val) {
                                return _vm.onSelectAllToPay(val)
                              },
                            },
                            model: {
                              value: _vm.allCheckedToPay,
                              callback: function ($$v) {
                                _vm.allCheckedToPay = $$v
                              },
                              expression: "allCheckedToPay",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(bulkPay)",
              fn: function (row) {
                return [
                  row.item.paymentDate
                    ? [
                        _c(
                          "p",
                          { staticClass: "h4" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "check-circle-fill",
                                variant: "primary",
                                title: _vm.$t("Pago"),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm.hasSelectRow
                    ? _c("FormulateInput", {
                        attrs: { type: "e-checkbox" },
                        on: {
                          change: function (val) {
                            return _vm.onSelectToPay(val, row.item)
                          },
                        },
                        model: {
                          value: row.item.checkedToPay,
                          callback: function ($$v) {
                            _vm.$set(row.item, "checkedToPay", $$v)
                          },
                          expression: "row.item.checkedToPay",
                        },
                      })
                    : [_vm._v(" " + _vm._s(_vm.getPaidStatusLabel(row)) + " ")],
                ]
              },
            },
            !_vm.isPdv
              ? {
                  key: "cell(store)",
                  fn: function (row) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("storeId")(row.item.accountsPayable.store)
                          )
                      ),
                      _c("br"),
                    ]
                  },
                }
              : null,
            !_vm.isPdv
              ? {
                  key: "cell(supplierOrCustomer)",
                  fn: function (row) {
                    return [
                      row.item.accountsPayable.supplier
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("cpfCnpj")(
                                    row.item.accountsPayable.supplier.document
                                  )
                                ) +
                                " - " +
                                _vm._s(
                                  row.item.accountsPayable.supplier.name ||
                                    row.item.accountsPayable.supplier
                                      .companyName
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      row.item.accountsPayable.customer
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(row.item.accountsPayable.customer.name) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                }
              : null,
            !_vm.isPdv
              ? {
                  key: "cell(statementDate)",
                  fn: function (row) {
                    return [
                      row.item.accountsPayable.statementDate
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("date")(
                                    row.item.accountsPayable.statementDate
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                }
              : null,
            !_vm.isPdv
              ? {
                  key: "cell(dueDate)",
                  fn: function (row) {
                    return [
                      row.item.dueDate
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("date")(row.item.dueDate)) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                }
              : null,
            !_vm.isPdv
              ? {
                  key: "cell(paymentMethod)",
                  fn: function (row) {
                    return [
                      _c(
                        "span",
                        { attrs: { title: row.item.paymentMethod.name } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("truncate")(
                                  row.item.paymentMethod.name || "-",
                                  20
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                }
              : null,
            !_vm.isPdv
              ? {
                  key: "cell(installment)",
                  fn: function (row) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.item.installment
                              ? [
                                  row.item.installment,
                                  row.item.accountsPayable.installmentCount,
                                ]
                                  .filter(Boolean)
                                  .join("/")
                              : "-"
                          ) +
                          " "
                      ),
                    ]
                  },
                }
              : null,
            !_vm.isPdv
              ? {
                  key: "cell(description)",
                  fn: function (row) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(row.item.accountsPayable.description || "-") +
                          " "
                      ),
                    ]
                  },
                }
              : null,
            !_vm.isPdv
              ? {
                  key: "cell(document)",
                  fn: function (row) {
                    return [
                      row.item.accountsPayable.document
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  "" +
                                    row.item.accountsPayable.document +
                                    (row.item.accountsPayable.documentType
                                      ? " (" +
                                        row.item.accountsPayable.documentType +
                                        ")"
                                      : "")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                }
              : null,
            {
              key: "custom-foot",
              fn: function () {
                return [
                  _vm.isPdv
                    ? [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { colspan: 2 },
                            },
                            [_vm._v(" Total ")]
                          ),
                          _c("th", { staticClass: "text-center" }, [
                            _vm._v(
                              " " + _vm._s(_vm._f("currency")(_vm.value)) + " "
                            ),
                          ]),
                          _c("th"),
                        ]),
                      ]
                    : [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { colspan: "7" },
                            },
                            [_vm._v(" Total ")]
                          ),
                          _c("th", { staticClass: "text-center" }, [
                            _vm._v(
                              " " + _vm._s(_vm._f("currency")(_vm.value)) + " "
                            ),
                          ]),
                          _c("th", { attrs: { colspan: "3" } }),
                        ]),
                        _vm.hasItemsSelected
                          ? _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "text-right",
                                  attrs: { colspan: "7" },
                                },
                                [_vm._v(" Total Selecionado ")]
                              ),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.getTotalInstallmentsSelected
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("th", { attrs: { colspan: "3" } }),
                            ])
                          : _vm._e(),
                      ],
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }