<template>
  <div>
    <b-table
      ref="bills-table"
      show-empty
      striped
      :small="!isPdv"
      responsive
      :class="{
        bordered: true,
        'mb-1': true,
        'bill-pay-select-row': hasSelectRow,
      }"
      :tbody-tr-class="rowClass"
      :fields="fields"
      :items="items"
      :empty-text="getEmptyTableMessage($tc('ACCOUNTS_PAYABLE.NAME'), 'female')"
      no-local-sorting
      :sort-by.sync="sorting.sortBy"
      :sort-desc.sync="sorting.sortDesc"
      @row-clicked="onRowClickToSelectPay"
      @sort-changed="getData"
    >
      <template #cell(action)="row">
        <e-grid-actions
          :show-update="!allActionsBlocked(row.item) && showUpdateAction()"
          :show-delete="!allActionsBlocked(row.item) && canDelete(row.item)"
          :show-read-only="showReadOnlyAction()"
          :buttons="gridActions(row)"
          @update="updateBill(row)"
          @pay-account="showPayModal(row)"
          @reverse-payment="reversePayment(row.item)"
          @read-only="showDetails(row)"
          @delete="onDelete(row.item)"
          @clone="clone(row.item)"
        />
      </template>

      <!-- ######## -->
      <!-- no PDV -->
      <!-- ######## -->
      <template
        v-if="isPdv"
        #cell(aditionalInfo)="row"
      >
        <b>{{ $t('Conta Gerencial') }}:</b> {{ row.item.accountsPayable.managementAccount.name
        }}<br>
        <div v-if="row.item.accountsPayable.supplier">
          <b>{{ $t('Fornecedor') }}:</b>
          {{ row.item.accountsPayable.supplier.document | cpfCnpj }} -
          {{ row.item.accountsPayable.supplier.name }}
        </div>
        <div v-if="row.item.accountsPayable.customer">
          <b>{{ $t('Cliente') }}:</b> {{ row.item.accountsPayable.customer.name }}
        </div>
        <b>{{ $t('Desc.') }}:</b> {{ row.item.accountsPayable.description || '-' }}<br>
        <div v-if="row.item.accountsPayable.payBox">
          <b>{{ $t('Caixa Nº') }}:</b> {{ row.item.accountsPayable.payBox.number || '-' }}<br>
        </div>
        <b>{{ $t('Criado por') }}:</b> {{ row.item.createdBy || '-' }} ({{
          row.item.createdDate | datetime
        }})<br>
        <div v-if="showModifiedBy(row.item)">
          <b>{{ $t('Alterado por') }}:</b>
          {{ row.item.modifiedBy || '-' }} ({{ row.item.modifiedDate | datetime }})<br>
        </div>
        <div v-if="row.item.writtenOffBy">
          <b>{{
            row.item.financialWriteOffIsActive ? $t('Baixado por') : $t('Baixa estornada por')
          }}:</b>
          {{ row.item.writtenOffBy || '-' }} ({{ row.item.writtenOffDate | datetime }})<br>
        </div>

        <div v-if="row.item.checkingAccount">
          <b>{{ $t('Conta corrente') }}:</b>
          <span> {{ row.item.checkingAccount ? row.item.checkingAccount.name : '' }} </span>
        </div>

        <div v-if="row.item.accountsPayable.comments || row.item.comments">
          <b>{{ $t('Observação') }}:</b>
          <span>
            {{
              [row.item.accountsPayable.comments, row.item.comments].filter(Boolean).join(' - ')
            }} </span><br>
        </div>
      </template>

      <template
        v-if="isPdv"
        #cell(info)="row"
      >
        <b>{{ $t('Id da Parcela') }}:</b> {{ row.item.id }}<br>
        <b>{{ $t('Loja') }}:</b> {{ row.item.accountsPayable.store | storeName }}<br>
        <div v-if="row.item.accountsPayable.document">
          <b>{{ $t('Nº doc.') }}:</b>
          {{
            `${row.item.accountsPayable.document}${
              row.item.accountsPayable.documentType
                ? ` (${row.item.accountsPayable.documentType})`
                : ''
            }`
          }}
        </div>
        <b>{{ $t('Parc.') }}:</b>
        {{
          row.item.installment
            ? [row.item.installment, row.item.accountsPayable.installmentCount]
              .filter(Boolean)
              .join('/')
            : '-'
        }}<br>
        <b>{{ $t('Forma Pagto.') }}:</b> {{ row.item.paymentMethod.name }}<br>
        <div v-if="row.item.accountsPayable.purchaseOrderId">
          <b>{{ $t('Pedido') }}:</b>&nbsp;
          <router-link
            :to="{
              name: 'purchase-order-details',
              params: { id: row.item.accountsPayable.purchaseOrderId },
            }"
            class="hyperlink"
          >
            <feather-icon icon="ExternalLinkIcon" />
            {{ row.item.accountsPayable.purchaseOrderId }}
          </router-link>
        </div>
        <div v-if="row.item.accountsPayable.saleId">
          <b>{{ $t('Venda') }}:</b>&nbsp;
          <router-link
            :to="{ name: 'order-read-only', params: { id: row.item.accountsPayable.saleId } }"
            class="hyperlink"
          >
            <feather-icon icon="ExternalLinkIcon" />
            {{ row.item.accountsPayable.saleId }}
          </router-link>
        </div>

        <div
          v-if="
            row.item.accountsPayable.statementDate &&
              row.item.accountsPayable.statementDate !== row.item.accountsPayable.createdDate
          "
        >
          <b>{{ $t('Emis.') }}:</b>&nbsp;
          {{ row.item.accountsPayable.statementDate | date }}
        </div>

        <b>{{ $t('Lanç.') }}:</b>
        <span v-if="row.item.accountsPayable.createdDate">
          {{ row.item.accountsPayable.createdDate | date }}
        </span>
        <span v-else> - </span>
        <br>

        <b>{{ $t('Venc.') }}:</b>
        <span v-if="row.item.dueDate"> {{ row.item.dueDate | date }} </span>
        <span v-else> - </span>
        <br>

        <b>{{ $t('Pago') }}:</b>
        <span v-if="row.item.paymentDate"> {{ row.item.paymentDate | date }} </span>
        <span v-else> - </span>
      </template>

      <!-- ######### -->
      <!-- no ERP -->
      <!-- ######### -->
      <template #head(bulkPay)>
        <p
          v-if="hasSelectRow"
          class="ml-1"
        >
          <e-instructions
            :title="$t('Baixa em massa')"
            :instruction="
              $t(
                'Selecione os registros para baixar e depois vá no botão inferior direto da tela para ver as opções de pagamento em massa.'
              )
            "
          />
          <FormulateInput
            v-model="allCheckedToPay"
            type="e-checkbox"
            class="mt-1"
            :title="$t('Marca ou desmarca todos os itens desta página.')"
            @change="(val) => onSelectAllToPay(val)"
          />
        </p>
      </template>
      <template #cell(bulkPay)="row">
        <template v-if="row.item.paymentDate">
          <p class="h4">
            <b-icon
              icon="check-circle-fill"
              variant="primary"
              :title="$t('Pago')"
            />
          </p>
        </template>
        <FormulateInput
          v-else-if="hasSelectRow"
          v-model="row.item.checkedToPay"
          type="e-checkbox"
          @change="(val) => onSelectToPay(val, row.item)"
        />
        <template v-else>
          {{ getPaidStatusLabel(row) }}
        </template>
      </template>

      <template
        v-if="!isPdv"
        #cell(store)="row"
      >
        {{ row.item.accountsPayable.store | storeId }}<br>
      </template>

      <template
        v-if="!isPdv"
        #cell(supplierOrCustomer)="row"
      >
        <div v-if="row.item.accountsPayable.supplier">
          {{ row.item.accountsPayable.supplier.document | cpfCnpj }} -
          {{
            row.item.accountsPayable.supplier.name || row.item.accountsPayable.supplier.companyName
          }}
        </div>
        <div v-if="row.item.accountsPayable.customer">
          {{ row.item.accountsPayable.customer.name }}
        </div>
      </template>

      <template
        v-if="!isPdv"
        #cell(statementDate)="row"
      >
        <span v-if="row.item.accountsPayable.statementDate">
          {{ row.item.accountsPayable.statementDate | date }}
        </span>
        <span v-else> - </span>
      </template>

      <template
        v-if="!isPdv"
        #cell(dueDate)="row"
      >
        <span v-if="row.item.dueDate"> {{ row.item.dueDate | date }} </span>
        <span v-else> - </span>
      </template>

      <template
        v-if="!isPdv"
        #cell(paymentMethod)="row"
      >
        <span :title="row.item.paymentMethod.name">
          {{ row.item.paymentMethod.name || '-' | truncate(20) }}
        </span>
      </template>

      <template
        v-if="!isPdv"
        #cell(installment)="row"
      >
        {{
          row.item.installment
            ? [row.item.installment, row.item.accountsPayable.installmentCount]
              .filter(Boolean)
              .join('/')
            : '-'
        }}
      </template>

      <template
        v-if="!isPdv"
        #cell(description)="row"
      >
        {{ row.item.accountsPayable.description || '-' }}
      </template>

      <template
        v-if="!isPdv"
        #cell(document)="row"
      >
        <div v-if="row.item.accountsPayable.document">
          {{
            `${row.item.accountsPayable.document}${
              row.item.accountsPayable.documentType
                ? ` (${row.item.accountsPayable.documentType})`
                : ''
            }`
          }}
        </div>
      </template>

      <template #custom-foot>
        <template v-if="isPdv">
          <tr>
            <th
              :colspan="2"
              class="text-right"
            >
              Total
            </th>
            <th class="text-center">
              {{ value | currency }}
            </th>
            <th />
          </tr>
        </template>
        <template v-else>
          <tr>
            <th
              colspan="7"
              class="text-right"
            >
              Total
            </th>
            <th class="text-center">
              {{ value | currency }}
            </th>
            <th colspan="3" />
          </tr>

          <tr v-if="hasItemsSelected">
            <th
              colspan="7"
              class="text-right"
            >
              Total Selecionado
            </th>
            <th class="text-right">
              {{ getTotalInstallmentsSelected | currency }}
            </th>
            <th colspan="3" />
          </tr>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment'
import { BTable } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import { formulateValidation, translate } from '@/mixins'
import EGridActions from '@/views/components/EGridActions.vue'
import EInstructions from '@/views/components/EInstructions.vue'

export default {
  components: {
    BTable,
    EGridActions,
    EInstructions,
  },

  mixins: [formulateValidation, translate],

  props: {
    items: {
      type: Array,
      required: true,
    },
    sorting: {
      type: Object,
      required: true,
    },
    isPdv: {
      type: Boolean,
      default: false,
    },
    showReadOnly: {
      type: Boolean,
      default: false,
    },
    summaryFields: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      allCheckedToPay: false,
    }
  },

  computed: {
    ...mapState('pages/financial/accountsPayable', ['selectedInstallments']),

    hasSelectRow() {
      return !this.isPdv
    },

    hasItemsSelected() {
      return this.selectedInstallments.length > 0
    },

    getTotalInstallmentsSelected() {
      return this.selectedInstallments.reduce((total, inst) => total + inst.value, 0)
    },

    value() {
      return this.summaryFields?.value?.value
    },

    fields() {
      if (this.isPdv) {
        return [
          {
            label: this.$t('Ações'),
            key: 'action',
            class: 'text-center',
            thStyle: { width: '100px' },
          },
          {
            label: this.$t('Informações'),
            key: 'info',
            thClass: 'text-center',
            tdClass: 'text-left',
          },
          {
            label: this.$t('Valor'),
            key: 'value',
            thClass: 'text-center',
            tdClass: 'text-right',
            thStyle: { minWidth: '110px', width: '110px' },
            formatter: val => this.$options.filters.currency(val),
            sortable: true,
          },
          {
            label: this.$t('Info. Adicionais'),
            key: 'aditionalInfo',
            thClass: 'text-center',
            tdClass: 'text-left',
          },
        ]
      }

      return [
        {
          label: this.$t('Status pagamento'),
          key: 'bulkPay',
          tdClass: 'pl-2 justify-content-center align-items-center',
          thStyle: { width: '50px' },
        },
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Emis.'),
          key: 'statementDate',
          sortKey: 'accountsPayable.statementDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Venc.'),
          key: 'dueDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          sortKey: 'accountsPayable.storeId',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { minWidth: '60px', width: '60px' },
          sortable: true,
        },
        {
          label: this.$t('Fornecedor/Cliente'),
          key: 'supplierOrCustomer',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { minWidth: '250px', width: '250px' },
        },
        {
          label: this.$t('Descrição'),
          key: 'description',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { minWidth: '300px', width: '300px' },
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          tdClass: 'text-right',
          thStyle: { minWidth: '110px', width: '110px' },
          formatter: val => this.$options.filters.currency(val),
          sortable: true,
        },
        {
          label: this.$t('Forma de pagamento'),
          key: 'paymentMethod',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { minWidth: '100px', width: '100px' },
        },
        {
          label: this.$t('Nº Doc'),
          key: 'document',
          tdClass: 'text-left',
          thStyle: { width: '200px' },
        },
        {
          label: this.$t('Parcela'),
          key: 'installment',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { minWidth: '60px', width: '60px' },
        },
      ]
    },

    gridActions() {
      return row =>
        [
          {
            icon: 'cash',
            variant: 'outline-primary',
            title: this.$t('Realizar Baixa'),
            event: 'pay-account',
            hide:
              !!row.item.paymentDate ||
              this.isPdv ||
              !this.$can('UpdatePayment', 'AccountsPayableInstallment'),
          },
          {
            icon: 'x-circle',
            variant: 'danger',
            title: this.$t('Estornar Baixa'),
            event: 'reverse-payment',
            hide:
              !row.item.paymentDate ||
              this.isPdv ||
              row.item.accountsPayable?.payBox?.id ||
              !this.$can('Reverse', 'FinancialWriteOff'),
          },
          {
            icon: 'intersect',
            variant: 'outline-primary',
            title: this.$t('Clonar'),
            event: 'clone',
            hide: this.isPdv,
          },
        ].filter(btn => !btn.hide && !this.allActionsBlocked(row.item))
    },
  },

  watch: {
    items() {
      this.verifyGridItensSelect()
    },
  },

  mounted() {},

  methods: {
    ...mapActions('pages/financial/accountsPayable', [
      'fetchAccountsPayable',
      'addInstallmentsToPay',
      'removeInstallmentsToPay',
    ]),
    async getData() {
      this.$emit('fetch-data')
    },

    verifyGridItensSelect() {
      const hasUnselectedRow = this.items.some(
        row => !this.selectedInstallments.some(selected => row.id === selected.id)
      )
      this.allCheckedToPay = !hasUnselectedRow
    },

    getPaidStatusLabel(row) {
      return new Date(row.item?.dueDate) < new Date() ? this.$t('Em atraso') : this.$t('Pendente')
    },

    showUpdateAction() {
      return !this.isPdv && this.$can('Update', 'AccountsPayable')
    },

    showReadOnlyAction() {
      return this.isPdv || this.showReadOnly
    },

    showPayModal(row) {
      this.$emit('show-pay-modal', row)
    },

    reversePayment(item) {
      this.$emit('reverse-payment', item)
    },

    showDetails(row) {
      this.$emit('show-detail', row)
    },

    async onRowClickToSelectPay(item) {
      if (!this.hasSelectRow) return

      if (item.paymentDate === null) {
        const checkValue = !item.checkedToPay
        // eslint-disable-next-line no-param-reassign
        item.checkedToPay = checkValue
        this.onSelectToPay(checkValue, item)
      }
    },

    async onSelectAllToPay(value) {
      let errorMessage = ''
      this.items
        .filter(item => item.paymentDate === null)
        .forEach(item => {
          try {
            // eslint-disable-next-line no-param-reassign
            item.checkedToPay = value
            if (value) {
              this.addInstallmentsToPay(item)
            } else {
              this.removeInstallmentsToPay(item)
            }
          } catch (e) {
            errorMessage = e.message
            // eslint-disable-next-line no-param-reassign
            item.checkedToPay = !value
          }
        })
      if (errorMessage) {
        this.showWarning({ message: errorMessage })
      }
    },

    async onSelectToPay(value, item) {
      if (!this.hasSelectRow) return

      try {
        if (value) {
          await this.addInstallmentsToPay(item)
        } else {
          await this.removeInstallmentsToPay(item)
        }
      } catch (error) {
        this.showWarning({ message: error.message })
        // eslint-disable-next-line no-param-reassign
        item.checkedToPay = !value
      }
    },

    updateBill(row) {
      this.$emit('update-account-payable', row)
    },

    async onDelete(item) {
      const confirm = await this.confirm({
        text: `Tem certeza que deseja excluir a despesa de ${this.$options.filters.currency(
          item.value
        )} do fornecedor ${
          item.accountsPayable?.supplier?.companyName
        }? Não há como desfazer esta ação.`,
      })
      if (confirm) {
        this.$emit('delete-installment', item.id)
      }
    },

    allActionsBlocked(item) {
      // pagamentos que tem origem uma transferência bancária, devem ser estornados na tela de transferências
      return !!item.accountsPayable?.checkingAccountTransferId
    },

    canDelete(item) {
      return (
        !item.accountsPayable?.purchaseOrderId &&
        !item.accountsPayable?.SaleId &&
        (!item.paymentDate || item.payBoxId)
      )
    },

    clone(item) {
      this.$emit('clone-accounts-payable', item.accountsPayable.id)
    },

    rowClass(item, type) {
      if (!this.hasSelectRow) return ''

      let clazz = item?.checkedToPay ? 'selected-row' : ''
      if (item && type === 'row') {
        if (!item.paymentDate) {
          clazz += ' payment-pending'
        }
      }
      return clazz
    },

    showModifiedBy(item) {
      return (
        item.modifiedDate &&
        (Math.abs(moment(item.modifiedDate).diff(item.writtenOffDate, 'seconds')) > 5 ||
          !item.writtenOffDate)
      )
    },
  },
}
</script>

<style lang="scss">
.bill-pay-select-row {
  & table tbody .payment-pending td {
    cursor: pointer !important;
  }
}
</style>
